import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <>
      <section className="section-secondary relative pt-8 text-white bg-black px-5 pb-10" style={{ borderTop: '1px solid #404040' }}>
        <div className="grid grid-cols-12 max-w-screen-2xl mx-auto xl:mb-8 mb-0 flex justify-between">
          {/*logo*/}
          <div className="xl:col-span-4 col-span-12 xl:mt-0 mt-28  xl:mb-0 mb-10 xl:text-left text-center">
            <Link to="/">
              <StaticImage
                className="w-full xl:max-w-[140px] max-w-[140px] mx-auto"
                src="../../images/logo-white.png"
                formats={['auto', 'webp', 'avif']}
                width={500}
                alt="Girar Logo"
                placeholder="blurred"
              />
            </Link>
          </div>
          <div className="xl:col-span-4 col-span-12  xl:mb-0 mb-5">
            <div className="flex items-center justify-center ">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/WeltgeistKiran">
                {' '}
                <FaFacebookF size="25" className="mx-3 text-white navi-icons" />{' '}
              </a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/WeltgeistKiran">
                {' '}
                <FaTwitter size="25" className="mx-3 text-white navi-icons" />{' '}
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/originalsin_0421/">
                {' '}
                <FaInstagram size="25" className="mx-3 text-white navi-icons" />{' '}
              </a>
            </div>
          </div>

          <div className="xl:col-span-4 col-span-12 text-center xl:mb-0 mb-5 xl:ml-auto">
            <StaticImage
              className="mx-1"
              src={'../images/amex-icon.png'}
              formats={['auto', 'webp', 'avif']}
              width={40}
              alt=""
              placeholder="blurred"
            />
            <StaticImage
              className="mx-1"
              src={'../images/jcb-icon.png'}
              formats={['auto', 'webp', 'avif']}
              width={40}
              alt=""
              placeholder="blurred"
            />
            <StaticImage
              className="mx-1"
              src={'../images/mastercard-icon.png'}
              formats={['auto', 'webp', 'avif']}
              width={40}
              alt=""
              placeholder="blurred"
            />
            <StaticImage
              className="mx-1"
              src={'../images/paypal-icon.png'}
              formats={['auto', 'webp', 'avif']}
              width={40}
              alt=""
              placeholder="blurred"
            />
            <StaticImage
              className="mx-1"
              src={'../images/visa-icon.png'}
              formats={['auto', 'webp', 'avif']}
              width={40}
              alt=""
              placeholder="blurred"
            />
          </div>
        </div>
        {/*last row*/}
        <div className="grid xl:grid-cols-12 grid-cols-12 max-w-screen-2xl mx-auto">
          <div className="xl:col-span-6 col-span-12 xl:text-left text-center xl:mb-0 mb-5 xl:block hidden">
            <p className="text-xs mb-0 xl:mt-0 mt-5">Copyright {new Date().getFullYear()} Girar World. All rights reserved.</p>
          </div>
          <div className="xl:col-span-6 col-span-12 xl:flex flex-row items-end grid xl:mt-0 mt-5">
            <div className="hover:underline  text-center xl:mb-0 mb-10  xl:ml-auto col-span-12">
              <Link to="/#author" className="hover:underline text-xs">
                About
              </Link>
            </div>
            <div className="hover:underline  text-center xl:mb-0 mb-10  xl:ml-auto col-span-12">
              <Link to="/refund-and-cancellation-policy" className="hover:underline text-xs">
                Refund & Cancellation
              </Link>
            </div>
            <div className="hover:underline  text-center xl:mb-0 mb-10  xl:ml-auto col-span-12">
              <Link to="/privacy-policy" className="hover:underline text-xs">
                Privacy policy
              </Link>
            </div>
            <div className="hover:underline  text-center xl:mb-0 mb-10  xl:ml-auto col-span-12">
              <Link to="/terms-and-conditions" className="hover:underline text-xs">
                Terms & Conditions
              </Link>
            </div>

            <div className="hover:underline  text-center xl:mb-0 mb-10  xl:ml-auto col-span-12">
              <Link to="/contact-us" className="hover:underline text-xs">
                Contact Us
              </Link>
            </div>
            <div className="xl:col-span-6 col-span-12 xl:text-left text-center xl:mb-0 mb-5 xl:hidden block">
              <p className="font-bold text-base mb-0 xl:mt-0 mt-5">
                Copyright {new Date().getFullYear()} Girar World. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
